/* eslint-disable  */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  InputBase,
  Paper,
  TextField,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { currentchannel } from 'feactures/channel/channelSlice';
import { errorResMessage, setError } from 'feactures/errorSlice';
import {
  fetchDiagnosisProductDelete,
  fetchDiagnosisProductList,
  fetchRegisterDiagnosisProduct,
  fetchReviewReport,
  performancereviewOtherItemList,
  performancereviewOwnItemList,
  performancereviewRegisterLoading,
  fetchAmazonSellerList,
  selectedSellerInfo
} from 'feactures/performancereview/performancereviewSlice';
import AmazonSellerModal from './AmazonSellerModal';

const PerformanceReviewRegisterDialog = (props) => {
  const { section, itemId } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputAsin, setInputAsin] = useState('');
  const [inputWord, setInputWord] = useState('');
  const [flag, setFlag] = useState(true);
  const errorMessage = useSelector(errorResMessage); //errorメッセージ
  const channel = useSelector(currentchannel);
  const registerLoading = useSelector(performancereviewRegisterLoading);
  const [inputError, setInputError] = useState('');
  const [sellerModalOpen, setSellerModalOpen] = useState(false);
  const sellerInfo = useSelector(selectedSellerInfo);
  useEffect(() => {
    const containsSpecialCharacters = /[\s\\￥¥]/.test(inputAsin);
    if (containsSpecialCharacters) {
      setInputError('登録できない文字が含まれます');
      setFlag(true);
    }
    // 楽天
    else if (channel == 2) {
      var asinArray = String(inputAsin).split(',');
      const pattern = /^[^:]+:[^:]+$/;
      for (const asin of asinArray) {
        if (pattern.test(asin)) {
          setFlag(false);
        } else {
          setFlag(true);
          if (inputAsin != '') {
            setInputError('「shop code:item id」の形で入力してください');
          }
        }
      }
    } else {
      if (inputAsin) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputAsin]);

  const ownItemList = useSelector(performancereviewOwnItemList);
  const otherItemList = useSelector(performancereviewOtherItemList);

  let color;
  let buttonColor;
  let sectionName;
  let itemList;
  let section_id;
  if (section == 'own') {
    color = 'rgba(113,140,198,1)';
    buttonColor = 'groupSelected';
    sectionName = '自社';
    itemList = ownItemList;
    section_id = 1;
  } else if (section == 'other') {
    color = '#ffa500';
    buttonColor = 'otherButton';
    sectionName = '競合';
    itemList = otherItemList;
    section_id = 2;
  }

  const handleClickOpen = () => {
    dispatch(fetchDiagnosisProductList({ section: section_id }));
    setOpen(true);
  };
  const handleClose = () => {
    setChecked(itemId);
    setOpen(false);
  };
  const handleComplete = () => {
    setOpen(false);
    let item_id;
    let shop_code;
    let targetRecord;
    if (channel == 1) {
      item_id = checked.split(':')[1];
      shop_code = checked.split(':')[0];
      targetRecord = itemList.find(
        (record) => record.itemId === item_id && record.shop_code === shop_code
      );
    } else if (channel == 2) {
      item_id = checked.split(':')[1];
      shop_code = checked.split(':')[0];
      targetRecord = itemList.find(
        (record) => record.itemId === item_id && record.shop_code === shop_code
      );
    }
    const firstdayFlag = targetRecord ? targetRecord.firstday_flag : 0;
    dispatch(fetchReviewReport({ item_id, shop_code, section, firstdayFlag }));
  };
  const handleChangeWord = (e) => {
    e.preventDefault();
    const word = e.target.value;
    setInputWord(word);
  };
  const handleSearch = () => {
    console.log('検索');
  };
  const [checked, setChecked] = useState(itemId);

  const handleToggle = (value) => () => {
    let item_code = `${value.shop_code}:${value.itemId}`;
    // if (channel == 1) {
    //   item_code = value.itemId;
    // } else if (channel == 2) {
    //   item_code = `${value.shop_code}:${value.itemId}`;
    // }
    setChecked(item_code);
  };

  const handleDelete = async (id) => {
    window.confirm(
      '商品の削除\nこの商品を削除すると、データの蓄積がストップします。\n本当に削除しますか？'
    );
    await dispatch(fetchDiagnosisProductDelete({ id }))
      .then(unwrapResult) //registerProductsで登録が成功（fullfilled）のときはモーダルを閉じる
      .then(() => {
        dispatch(fetchDiagnosisProductList({ section: section_id }));
        setInputAsin('');
      });
  };

  const changeAsin = (e) => {
    e.preventDefault();
    dispatch(setError(''));
    setInputError('');
    const asinList = e.target.value;
    setInputAsin(asinList);
  };

  const registerHandle = async () => {
    let item_id = '';
    let shop_code = '';
    let shop_name = '';
    if (channel == 1) {
      item_id = inputAsin;
      shop_code = sellerInfo.id;
      shop_name = sellerInfo.name;
    } else if (channel == 2) {
      const asinArray = String(inputAsin).split(':');
      item_id = asinArray[1];
      shop_code = asinArray[0];
      shop_name = '';
    }
    dispatch(setError(''));
    await dispatch(
      fetchRegisterDiagnosisProduct({
        item_id,
        shop_code,
        shop_name,
        section: section_id
      })
    )
      .then(unwrapResult) //registerProductsで登録が成功（fullfilled）のときはモーダルを閉じる
      .then(() => {
        dispatch(fetchDiagnosisProductList({ section: section_id }));
        setInputAsin('');
      })
      .catch((err) => {
        setInputAsin(inputAsin);
      });
  };

  const getAmazonSeller = () => {
    const item_id = inputAsin;
    dispatch(fetchAmazonSellerList({ item_id: item_id }));
    setSellerModalOpen(true);
  };

  return (
    <>
      <Box>
        <Button
          variant="contained"
          size="small"
          color={buttonColor}
          onClick={handleClickOpen}
        >
          +追加/編集
        </Button>
        <Dialog open={open} onClose={handleClose} maxWidth="60％">
          <Box p={2}>
            <Typography fontSize="18px" fontWeight="bold" color={color}>
              {sectionName}商品の追加/編集
            </Typography>
            <Typography color="text.secondary">
              商品を選択して編集するか、新しい商品を追加してください。
            </Typography>
            <Box my={2}>
              {/* 検索 */}
              {/* <Box>
                <Typography color="text.secondary">商品を検索</Typography>
                <Paper
                  component={'div'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'calc(100% - 8px)',
                    height: '30px',
                    ml: 1
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="商品名を入力..."
                    value={inputWord}
                    onChange={(e) => {
                      handleChangeWord(e);
                    }}
                    inputProps={{
                      style: { fontSize: '13px' }
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: '5px', mr: 1 }}
                    onClick={() => {
                      handleSearch();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Box> */}
              {/* 商品リスト */}
              {registerLoading ? (
                <Box mt={2} display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : itemList.length == 0 ? (
                <Box mt={2} display="flex" justifyContent="center">
                  <Typography color="text.secondary">
                    商品を登録してください
                  </Typography>
                </Box>
              ) : (
                <Box mt={2}>
                  <Typography color="text.secondary">
                    商品リスト　制限10個
                  </Typography>
                  <Box border={1} borderColor="#F5F5F5" borderRadius={3} p={1}>
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 300,
                        '& ul': { padding: 0 }
                      }}
                      subheader={<li />}
                    >
                      {itemList.map((value, index) => {
                        const labelId = value.itemId;
                        const truncatedText =
                          value.itemName.length > 50
                            ? value.itemName.slice(0, 50) + '...'
                            : value.itemName;
                        return (
                          <ListItem
                            key={value.id}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  handleDelete(value.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                            disablePadding
                            sx={{ height: '50px' }}
                          >
                            <ListItemButton
                              role={undefined}
                              onClick={handleToggle(value)}
                              dense
                            >
                              <ListItemIcon>
                                {checked ==
                                  `${value.shop_code}:${value.itemId}` && (
                                  <TaskAltIcon color="success" />
                                )}
                              </ListItemIcon>
                              <Tooltip
                                title={value.itemName}
                                placement="top"
                                arrow
                              >
                                <ListItemText
                                  id={labelId}
                                  primary={
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '20px'
                                      }}
                                    >
                                      <Typography>{index + 1}</Typography>
                                      <Box>
                                        <Typography component="span">
                                          {truncatedText}
                                        </Typography>
                                        {channel == 1 && (
                                          <>
                                            <br />
                                            <Typography component="span">
                                              セラー：{value.shop_name}
                                            </Typography>
                                          </>
                                        )}
                                      </Box>
                                    </Box>
                                  }
                                  sx={{
                                    marginLeft: '-15px',
                                    maxWidth: '90%',
                                    marginRight: 1
                                  }}
                                />
                              </Tooltip>
                              {checked ==
                              `${value.shop_code}:${value.itemId}` ? (
                                <Typography color="#00C367" ml="auto">
                                  選択中
                                </Typography>
                              ) : (
                                <Box width="36px"></Box>
                              )}
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Box>
              )}
              {/* 商品追加 */}
              <Box mt={2}>
                <Typography color="text.secondary">新しい商品を追加</Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Paper
                    component={'div'}
                    sx={{
                      isplay: 'flex',
                      alignItems: 'center',
                      width: 'calc(100% - 64px)',
                      height: '30px',
                      ml: 1
                    }}
                  >
                    <InputBase
                      sx={{
                        ml: 1,
                        flex: 1,
                        width: 'calc(100% - 20px)'
                      }}
                      placeholder={
                        channel == 1
                          ? 'ASINを入力'
                          : channel == 2
                          ? 'ショップコード:商品管理番号を入力'
                          : '商品番号を入力'
                      }
                      value={inputAsin}
                      onChange={changeAsin}
                      inputProps={{
                        style: { fontSize: '13px' }
                      }}
                    />
                  </Paper>
                  <Button
                    disabled={flag}
                    onClick={channel == 1 ? getAmazonSeller : registerHandle}
                    variant="contained"
                    color={buttonColor}
                    sx={{ height: '30px', minWidth: '56px' }}
                  >
                    {registerLoading ? <CircularProgress size={15} /> : '追加'}
                  </Button>
                </Box>
                {inputError != '' && (
                  <Typography color="red">{inputError}</Typography>
                )}
                {errorMessage && (
                  <Typography color="red">{errorMessage}</Typography>
                )}
                <Typography color="text.secondary" mt={1}>
                  {channel === 2 && (
                    <>
                      商品登録は、ショップコードと商品管理番号をコロン( :
                      )区切りで設定して下さい。
                      <br />
                    </>
                  )}
                  {/* 複数商品を登録する際は、カンマで区切ってください
                （最大で100個まで登録可能）
                <br /> */}
                  {/* {channel === 1 && <>※入力例：B2FBDK23, BLSIF6U7, BLDIEM5</>} */}
                  {channel === 2 && (
                    <>
                      ※入力例：oxcim-shop:1234567
                      <br />
                      ▼ショップコード確認方法
                      <br />
                      該当の商品ページを表示し、URLをご確認ください。
                      <br />
                      https://item.rakuten.co.jp/&#123;ショップコード&#125;/&#123;商品管理番号&#125;/
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
            <DialogActions>
              <Button onClick={handleClose} color="inherit" variant="contained">
                キャンセル
              </Button>
              <Button
                onClick={handleComplete}
                color={buttonColor}
                variant="contained"
              >
                完了
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
      <AmazonSellerModal
        item_id={inputAsin}
        open={sellerModalOpen}
        setOpen={setSellerModalOpen}
        registerHandle={registerHandle}
      />
    </>
  );
};

export default PerformanceReviewRegisterDialog;
