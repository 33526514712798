/* eslint-disable */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  performancereviewAmazonOtherScore,
  performancereviewAmazonOtherTrackingData,
  performancereviewAmazonOwnScore,
  performancereviewAmazonOwnTrackingData,
  performancereviewRakutenOtherScore,
  performancereviewRakutenOtherTrackingData,
  performancereviewRakutenOwnScore,
  performancereviewRakutenOwnTrackingData,
  performancereviewTermList
} from 'feactures/performancereview/performancereviewSlice';
import { currentchannel } from 'feactures/channel/channelSlice';

const PerformanceScoreTracker = (props) => {
  const { section } = props;
  const amazonOwnPerformanceData = useSelector(
    performancereviewAmazonOwnTrackingData
  );
  const rakutenOwnPerformanceData = useSelector(
    performancereviewRakutenOwnTrackingData
  );
  const amazonOtherPerformanceData = useSelector(
    performancereviewAmazonOtherTrackingData
  );
  const rakutenOtherPerformanceData = useSelector(
    performancereviewRakutenOtherTrackingData
  );
  const amazonOwnScore = useSelector(performancereviewAmazonOwnScore);
  const amazonOtherScore = useSelector(performancereviewAmazonOtherScore);
  const rakutenOwnScore = useSelector(performancereviewRakutenOwnScore);
  const rakutenOtherScore = useSelector(performancereviewRakutenOtherScore);
  const columns = useSelector(performancereviewTermList);
  const channel = useSelector(currentchannel);

  let sectionName;
  let color;
  let data;
  let score;
  if ((section == 'own') & (channel == 1)) {
    sectionName = '自社';
    color = 'rgba(113,140,198,1)';
    data = amazonOwnPerformanceData;
    score = amazonOwnScore;
  } else if ((section == 'other') & (channel == 1)) {
    sectionName = '競合';
    color = '#ffa500';
    data = amazonOtherPerformanceData;
    score = amazonOtherScore;
  } else if ((section == 'own') & (channel == 2)) {
    sectionName = '自社';
    color = 'rgba(113,140,198,1)';
    data = rakutenOwnPerformanceData;
    score = rakutenOwnScore;
  } else if ((section == 'other') & (channel == 2)) {
    sectionName = '競合';
    color = '#ffa500';
    data = rakutenOtherPerformanceData;
    score = rakutenOtherScore;
  }

  return (
    <Box>
      <Typography fontWeight="bold" fontSize="18px" color={color}>
        ■{sectionName}商品スコア推移
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 0, position: 'relative' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column} align="center">
                  <Typography
                    color="text.secondary"
                    fontWeight="bold"
                    fontSize="13px"
                  >
                    {column}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index1) => (
              <TableRow key={row.category}>
                <TableCell>
                  <Typography
                    color="black"
                    fontWeight="bold"
                    fontSize={index1 <= 1 ? '16px' : '13px'}
                  >
                    {row.category}
                  </Typography>
                </TableCell>
                {row.scores.map((score, index) => (
                  <TableCell key={index} align="center">
                    {index1 === 0 ? (
                      <img
                        alt="score"
                        src={`/static/images/performanceReview/rank_${score}.png`}
                        style={{
                          display: 'inline-block',
                          width: '35px'
                        }}
                      />
                    ) : (
                      <Typography
                        color={color}
                        fontWeight="bold"
                        fontSize={index1 <= 1 ? '17px' : '13px'}
                      >
                        {score === 0 || score === -99 ? (
                          '圏外'
                        ) : index1 === 1 ? (
                          score
                        ) : (
                          <img
                            alt="score"
                            src={`/static/images/performanceReview/rank_${score}.png`}
                            style={{
                              display: 'inline-block',
                              width: '25px'
                            }}
                          />
                        )}
                        {index1 === 1 && score !== 0 && score !== -99 && '位'}
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* TableContainer内にオーバーレイ表示 */}
        {score == 'nodata' && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              color: 'black',
              fontWeight: 'bold',
              fontSize: '15px',
              zIndex: 1
            }}
          >
            新規登録時はデータは翌日反映されます
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default PerformanceScoreTracker;
