/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchRegisterDiagnosisProduct = createAsyncThunk(
  'performancereview/register',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const { item_id, shop_code, shop_name, section } = arg;
    const res = await client.post(`${apiUrl}performance_review/register`, {
      accountId: accountId,
      channel: channel,
      itemId: item_id,
      shopCode: shop_code,
      shopName: shop_name,
      section: section
    });
    return res.data;
  }
);

export const fetchDiagnosisProductList = createAsyncThunk(
  'performancereview/get_item',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const { section } = arg;
    const res = await client.post(`${apiUrl}performance_review/get_item_list`, {
      accountId: accountId,
      channel: channel,
      section: section
    });
    return res.data;
  }
);

export const fetchDiagnosisProductDelete = createAsyncThunk(
  'performancereview/delete_item',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { id } = arg;
    const res = await client.post(`${apiUrl}performance_review/delete_item`, {
      accountId: accountId,
      id: id
    });
    return res.data;
  }
);

export const fetchReviewReport = createAsyncThunk(
  'performancereview/get_item_report',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { term, improvementsOrder } = thunkAPI.getState().performancereview;
    const { item_id, shop_code, section, firstdayFlag } = arg;
    const res = await client.post(
      `${apiUrl}performance_review/get_item_report`,
      {
        channel: channel,
        itemId: item_id,
        shopCode: shop_code,
        section: section,
        term: term,
        order: improvementsOrder,
        firstDayFlag: firstdayFlag
      }
    );
    return res.data;
  }
);

export const fetchReviewReportChangeTerm = createAsyncThunk(
  'performancereview/get_item_report/change_term',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { term, amazon, rakuten, improvementsOrder } =
      thunkAPI.getState().performancereview;
    const { section } = arg;
    let item_id = '';
    let shop_code = '';
    let firstDayFlag = 0;
    if ((section == 'own') & (channel == 1)) {
      item_id = amazon.ownItemData.itemId.split(':')[1];
      shop_code = amazon.ownItemData.itemId.split(':')[0];
      firstDayFlag = amazon.ownItemData.firstday_flag;
    } else if ((section == 'other') & (channel == 1)) {
      item_id = amazon.otherItemData.itemId.split(':')[1];
      shop_code = amazon.otherItemData.itemId.split(':')[0];
      firstDayFlag = amazon.otherItemData.firstday_flag;
    } else if ((section == 'own') & (channel == 2)) {
      if (rakuten.ownItemData.itemId == '') {
        item_id = '';
        shop_code = '';
        firstDayFlag = rakuten.ownItemData.firstday_flag;
      } else {
        item_id = rakuten.ownItemData.itemId.split(':')[1];
        shop_code = rakuten.ownItemData.itemId.split(':')[0];
        firstDayFlag = rakuten.ownItemData.firstday_flag;
      }
    } else if ((section == 'other') & (channel == 2)) {
      if (rakuten.otherItemData.itemId == '') {
        item_id = '';
        shop_code = '';
        firstDayFlag = rakuten.otherItemData.firstday_flag;
      } else {
        item_id = rakuten.otherItemData.itemId.split(':')[1];
        shop_code = rakuten.otherItemData.itemId.split(':')[0];
        firstDayFlag = rakuten.otherItemData.firstday_flag;
      }
    }
    const res = await client.post(
      `${apiUrl}performance_review/get_item_report`,
      {
        channel: channel,
        itemId: item_id,
        shopCode: shop_code,
        section: section,
        term: term,
        order: improvementsOrder,
        firstDayFlag: firstDayFlag
      }
    );
    return res.data;
  }
);

export const fetchReviewReportChangeOrder = createAsyncThunk(
  'performancereview/get_item_report/change_order',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { amazon, rakuten, improvementsOrder } =
      thunkAPI.getState().performancereview;
    let improvements;
    if (channel == 1) {
      improvements = amazon.ownItemData.improvements;
    } else if (channel == 2) {
      improvements = rakuten.ownItemData.improvements;
    }
    const res = await client.post(
      `${apiUrl}performance_review/change_improvements_order`,
      {
        improvements: improvements,
        order: improvementsOrder,
        channel: channel
      }
    );
    return res.data;
  }
);

export const fetchAmazonSellerList = createAsyncThunk(
  'performancereview/get_amazon_seller',
  async (arg, thunkAPI) => {
    const { item_id } = arg;
    const res = await client.post(`${apiUrl}performance_review/amazon_seller`, {
      item_id: item_id
    });
    return res.data;
  }
);

const getCurrentMonth = () => {
  const now = new Date();
  return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
};

const getPastMonths = (numMonths) => {
  const months = [];
  const now = new Date();
  for (let i = 0; i < numMonths; i++) {
    const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
    months.push(
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
    );
  }
  return months.reverse();
};

const performancereviewSlice = createSlice({
  name: 'newMeasureAnalysis',
  initialState: {
    term: getCurrentMonth(),
    termList: ['', ...getPastMonths(3)],
    amazon: {
      ownItemData: {
        itemName: '',
        itemId: '',
        score: '',
        categoryName: '',
        price: 0,
        aveRank: 0,
        reviewScore: 0,
        reviewNum: 0,
        graphData: [],
        trackingData: [
          { category: '総合評価', scores: [] },
          { category: '平均ランキング', scores: [] },
          { category: '集客施策', scores: [] },
          { category: '検索', scores: [] },
          { category: '価格', scores: [] },
          { category: '商品ページ', scores: [] },
          { category: '出品', scores: [] },
          { category: 'ストア', scores: [] }
        ],
        reviewResult: [],
        improvements: [],
        firstday_flag: 0
      },
      otherItemData: {
        itemName: '',
        itemId: '',
        score: '',
        categoryName: '',
        price: 0,
        aveRank: 0,
        reviewScore: 0,
        reviewNum: 0,
        graphData: [],
        trackingData: [
          { category: '総合評価', scores: [] },
          { category: '平均ランキング', scores: [] },
          { category: '集客施策', scores: [] },
          { category: '検索', scores: [] },
          { category: '価格', scores: [] },
          { category: '商品ページ', scores: [] },
          { category: '出品', scores: [] },
          { category: 'ストア', scores: [] }
        ]
      },
      sellerList: [],
      selectedSeller: '',
      firstday_flag: 0
    },
    rakuten: {
      ownItemData: {
        itemName: NaN,
        itemId: '',
        score: '',
        categoryName: '',
        price: 0,
        aveRank: 0,
        reviewScore: 0,
        reviewNum: 0,
        graphData: [],
        trackingData: [
          { category: '総合評価', scores: [] },
          { category: '平均ランキング', scores: [] },
          { category: '集客施策', scores: [] },
          { category: '検索', scores: [] },
          { category: '価格', scores: [] },
          { category: '商品ページ', scores: [] },
          { category: '出品', scores: [] },
          { category: 'ストア', scores: [] }
        ],
        reviewResult: [],
        improvements: [],
        firstday_flag: 0
      },
      otherItemData: {
        itemName: NaN,
        itemId: '',
        score: '',
        categoryName: '',
        price: 0,
        aveRank: 0,
        reviewScore: 0,
        reviewNum: 0,
        graphData: [],
        trackingData: [
          { category: '総合評価', scores: [] },
          { category: '平均ランキング', scores: [] },
          { category: '集客施策', scores: [] },
          { category: '検索', scores: [] },
          { category: '価格', scores: [] },
          { category: '商品ページ', scores: [] },
          { category: '出品', scores: [] },
          { category: 'ストア', scores: [] }
        ],
        firstday_flag: 0
      }
    },
    ownItemList: [],
    otherItemList: [],
    ownDataLoading: false,
    otherDataLoading: false,
    registerLoading: false,
    improvementsOrder: 'high',
    improvementsLoading: false,
    sellerLoading: false
  },
  reducers: {
    changePerformanceTerm(state, action) {
      state.term = action.payload;
    },
    changePerformanceTermList(state, action) {
      state.termList = action.payload;
    },
    setPriorityOrder(state, action) {
      state.improvementsOrder = action.payload;
    },
    setSellerInfo(state, action) {
      state.amazon.selectedSeller = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRegisterDiagnosisProduct.fulfilled,
      (state, action) => {
        return {
          ...state,
          registerLoading: false
        };
      }
    );
    builder.addCase(fetchRegisterDiagnosisProduct.pending, (state, action) => {
      return {
        ...state,
        registerLoading: true
      };
    });
    builder.addCase(fetchRegisterDiagnosisProduct.rejected, (state, action) => {
      return {
        ...state,
        registerLoading: false
      };
    });
    builder.addCase(fetchDiagnosisProductList.fulfilled, (state, action) => {
      if (action.payload.section == 1) {
        return {
          ...state,
          ownItemList: action.payload.data,
          registerLoading: false
        };
      } else if (action.payload.section == 2) {
        return {
          ...state,
          otherItemList: action.payload.data,
          registerLoading: false
        };
      }
    });
    builder.addCase(fetchDiagnosisProductList.pending, (state, action) => {
      return {
        ...state,
        registerLoading: true
      };
    });
    builder.addCase(fetchDiagnosisProductList.rejected, (state, action) => {
      return {
        ...state,
        registerLoading: false
      };
    });
    builder.addCase(fetchReviewReport.fulfilled, (state, action) => {
      if ((action.payload.section == 'own') & (action.payload.channel == 1)) {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            ownItemData: action.payload.data
          },
          ownDataLoading: false
        };
      } else if (
        (action.payload.section == 'other') &
        (action.payload.channel == 1)
      ) {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            otherItemData: action.payload.data
          },
          otherDataLoading: false
        };
      } else if (
        (action.payload.section == 'own') &
        (action.payload.channel == 2)
      ) {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            ownItemData: action.payload.data
          },
          ownDataLoading: false
        };
      } else if (
        (action.payload.section == 'other') &
        (action.payload.channel == 2)
      ) {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            otherItemData: action.payload.data
          },
          otherDataLoading: false
        };
      }
    });
    builder.addCase(fetchReviewReport.pending, (state, action) => {
      const { section } = action.meta.arg;
      return {
        ...state,
        otherDataLoading: section === 'other' ? true : state.otherDataLoading,
        ownDataLoading: section === 'own' ? true : state.ownDataLoading
      };
    });
    builder.addCase(fetchReviewReport.rejected, (state, action) => {
      const { section } = action.meta.arg;
      return {
        ...state,
        otherDataLoading: section === 'other' ? false : state.otherDataLoading,
        ownDataLoading: section === 'own' ? false : state.ownDataLoading
      };
    });
    builder.addCase(fetchReviewReportChangeTerm.fulfilled, (state, action) => {
      if ((action.payload.section == 'own') & (action.payload.channel == 1)) {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            ownItemData: action.payload.data
          },
          ownDataLoading: false
        };
      } else if (
        (action.payload.section == 'other') &
        (action.payload.channel == 1)
      ) {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            otherItemData: action.payload.data
          },
          otherDataLoading: false
        };
      } else if (
        (action.payload.section == 'own') &
        (action.payload.channel == 2)
      ) {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            ownItemData: action.payload.data
          },
          ownDataLoading: false
        };
      } else if (
        (action.payload.section == 'other') &
        (action.payload.channel == 2)
      ) {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            otherItemData: action.payload.data
          },
          otherDataLoading: false
        };
      }
    });
    builder.addCase(fetchReviewReportChangeTerm.pending, (state, action) => {
      const { section } = action.meta.arg;
      return {
        ...state,
        otherDataLoading: section === 'other' ? true : state.otherDataLoading,
        ownDataLoading: section === 'own' ? true : state.ownDataLoading
      };
    });
    builder.addCase(fetchReviewReportChangeTerm.rejected, (state, action) => {
      const { section } = action.meta.arg;
      return {
        ...state,
        otherDataLoading: section === 'other' ? false : state.otherDataLoading,
        ownDataLoading: section === 'own' ? false : state.ownDataLoading
      };
    });
    builder.addCase(fetchReviewReportChangeOrder.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            ownItemData: {
              ...state.amazon.ownItemData,
              improvements: action.payload.data
            }
          },
          improvementsLoading: false
        };
      } else if (action.payload.channel == 2) {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            ownItemData: {
              ...state.rakuten.ownItemData,
              improvements: action.payload.data
            }
          },
          improvementsLoading: false
        };
      }
    });
    builder.addCase(fetchReviewReportChangeOrder.pending, (state, action) => {
      return {
        ...state,
        improvementsLoading: true
      };
    });
    builder.addCase(fetchReviewReportChangeOrder.rejected, (state, action) => {
      return {
        ...state,
        improvementsLoading: false
      };
    });
    builder.addCase(fetchAmazonSellerList.fulfilled, (state, action) => {
      return {
        ...state,
        amazon: {
          ...state.amazon,
          sellerList: action.payload.seller_list
        },
        sellerLoading: false
      };
    });
    builder.addCase(fetchAmazonSellerList.pending, (state, action) => {
      return {
        ...state,
        sellerLoading: true
      };
    });
    builder.addCase(fetchAmazonSellerList.rejected, (state, action) => {
      return {
        ...state,
        sellerLoading: false
      };
    });
  }
});

export const {
  changePerformanceTerm,
  changePerformanceTermList,
  setPriorityOrder,
  setSellerInfo
} = performancereviewSlice.actions;
export const performancereviewTermList = (state) =>
  state.performancereview.termList;
export const performancereviewOwnDataLoading = (state) =>
  state.performancereview.ownDataLoading;
export const performancereviewOtherDataLoading = (state) =>
  state.performancereview.otherDataLoading;
export const performancereviewRegisterLoading = (state) =>
  state.performancereview.registerLoading;
export const performancereviewAmazonOwnItemData = (state) =>
  state.performancereview.amazon.ownItemData;
export const performancereviewRakutenOwnItemData = (state) =>
  state.performancereview.rakuten.ownItemData;
export const performancereviewAmazonOwnGraphData = (state) =>
  state.performancereview.amazon.ownItemData.graphData;
export const performancereviewRakutenOwnGraphData = (state) =>
  state.performancereview.rakuten.ownItemData.graphData;
export const performancereviewAmazonOwnTrackingData = (state) =>
  state.performancereview.amazon.ownItemData.trackingData;
export const performancereviewRakutenOwnTrackingData = (state) =>
  state.performancereview.rakuten.ownItemData.trackingData;
export const performancereviewAmazonOwnReviewResult = (state) =>
  state.performancereview.amazon.ownItemData.reviewResult;
export const performancereviewRakutenOwnReviewResult = (state) =>
  state.performancereview.rakuten.ownItemData.reviewResult;
export const performancereviewAmazonOwnImprovements = (state) =>
  state.performancereview.amazon.ownItemData.improvements;
export const performancereviewRakutenOwnImprovements = (state) =>
  state.performancereview.rakuten.ownItemData.improvements;
export const performancereviewAmazonOtherItemData = (state) =>
  state.performancereview.amazon.otherItemData;
export const performancereviewRakutenOtherItemData = (state) =>
  state.performancereview.rakuten.otherItemData;
export const performancereviewAmazonOtherGraphData = (state) =>
  state.performancereview.amazon.otherItemData.graphData;
export const performancereviewRakutenOtherGraphData = (state) =>
  state.performancereview.rakuten.otherItemData.graphData;
export const performancereviewAmazonOtherTrackingData = (state) =>
  state.performancereview.amazon.otherItemData.trackingData;
export const performancereviewRakutenOtherTrackingData = (state) =>
  state.performancereview.rakuten.otherItemData.trackingData;
export const performancereviewOwnItemList = (state) =>
  state.performancereview.ownItemList;
export const performancereviewOtherItemList = (state) =>
  state.performancereview.otherItemList;
export const performancereviewTerm = (state) => state.performancereview.term;
export const performancereviewOrder = (state) =>
  state.performancereview.improvementsOrder;
export const performancereviewOrderLoading = (state) =>
  state.performancereview.improvementsLoading;
export const sellerList = (state) => state.performancereview.amazon.sellerList;
export const sellerLoading = (state) => state.performancereview.sellerLoading;
export const selectedSellerInfo = (state) =>
  state.performancereview.amazon.selectedSeller;
export const performancereviewAmazonOwnScore = (state) =>
  state.performancereview.amazon.ownItemData.score;
export const performancereviewAmazonOtherScore = (state) =>
  state.performancereview.amazon.otherItemData.score;
export const performancereviewRakutenOwnScore = (state) =>
  state.performancereview.rakuten.ownItemData.score;
export const performancereviewRakutenOtherScore = (state) =>
  state.performancereview.rakuten.otherItemData.score;

export default performancereviewSlice.reducer;
