/* eslint-disable*/
import { useNavigate, useSearchParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemButton,
  CircularProgress
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  accountList,
  fetchAccountList,
  fetchMasterUser,
  userLoading
} from 'feactures/user/userSlice';
import { fetchNews } from 'feactures/user/newsSlice';
import { fetchYahooKey } from 'feactures/user/yahooKeySlice';
import { changeChannel } from 'feactures/channel/channelSlice';
import { errorResMessage } from 'feactures/errorSlice';
import { fetchValidCategoryList } from 'feactures/category/validCategoryListSlice';
import './Login.css';
import { fetchEnv } from 'feactures/env/envSlice';
import { fetchFavoriteCategory } from 'feactures/category/favoriteCategorySlice';

const MasterLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const errorMessage = useSelector(errorResMessage);
  const items = useSelector(accountList);
  const loading = useSelector(userLoading);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    dispatch(fetchAccountList({ searchWord: searchQuery }));
  }, []);

  const onSubmit = async (values) => {
    setError(null);
    await dispatch(fetchMasterUser(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then((res) => {
        dispatch(fetchEnv());
        setSuccess(true);
        if (
          res.plan.amazon.plan_id == '0' &&
          !res.trial['Amazon'].trial &&
          (res.plan.rakuten.plan_id !== '0' || res.trial['楽天'].trial)
        ) {
          dispatch(
            changeChannel({
              id: 2,
              name: 'rakuten'
            })
          );
        }
        dispatch(fetchValidCategoryList());
        dispatch(fetchFavoriteCategory());
        dispatch(fetchNews());
        dispatch(fetchYahooKey());
        setTimeout(function () {
          navigate('/app/measureanalysis');
        }, 500);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleSearch = () => {
    dispatch(fetchAccountList({ searchWord: searchQuery }));
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 'auto',
        minHeight: '100vh',
        width: '100%',
        pb: '80px',
        mt: 3
      }}
    >
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            account: null,
            userId: '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            acountId: Yup.string().max(255).required('アカウントが未選択です'),
            userId: Yup.string()
              .max(255)
              .required('メールアドレスが未入力です'),
            password: Yup.string().max(255).required('パスワードが未入力です')
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h2">マスターユーザーログイン</Typography>
                <Typography variant="h6" pl={1}>
                  こちらはマスターユーザー用ログイン画面です。
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Box
                  sx={{
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: 1,
                    p: 2
                  }}
                >
                  <Box display="flex">
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="会社名で検索"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleSearch();
                      }}
                      sx={{ ml: 1 }}
                    >
                      <SearchIcon />
                    </Button>
                  </Box>
                  <Typography mt={1}>
                    以下のリストからアカウントを選択してください。
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: 300,
                      overflow: 'auto',
                      mt: 1,
                      bgcolor: '#E8F0FE'
                    }}
                  >
                    {loading ? (
                      <Box
                        height="300px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Box>
                    ) : items.length > 0 ? (
                      <List>
                        {items.map((item, index) => (
                          <ListItemButton
                            key={index}
                            onClick={() => setFieldValue('account', item)}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  component="span"
                                  sx={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  ID: {item.id}　{item.company}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        一致する項目がありません
                      </Typography>
                    )}
                  </Box>
                  <Typography
                    fontSize="14px"
                    sx={{
                      mt: 2,
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    選択中のアカウント：
                    {values.account ? values.account.company : '未選択'}
                  </Typography>
                </Box>
              </Box>
              <TextField
                error={Boolean(touched.userId && errors.userId)}
                fullWidth
                helperText={touched.userId && errors.userId}
                label="メールアドレス"
                margin="normal"
                name="userId"
                onBlur={handleBlur}
                onChange={handleChange}
                type="userId"
                value={values.userId}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="パスワード"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                autoComplete="off"
              />
              <Box sx={{ py: 2 }}>
                {success ? (
                  <Alert severity="success">ログインに成功しました!</Alert>
                ) : (
                  <Button
                    // color="primary"
                    disabled={
                      isSubmitting ||
                      values.password == '' ||
                      values.userId == '' ||
                      !values.account
                    }
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => onSubmit(values)}
                  >
                    ログイン
                  </Button>
                )}
                {error && <Alert severity="error">{errorMessage}</Alert>}
              </Box>
              <Typography color="text.secondary" variant="h5">
                パスワードを忘れた方は管理者にお問い合わせください。
              </Typography>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default MasterLogin;
