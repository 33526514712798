/* eslint-disable*/
import { React, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Box,
  Divider,
  Typography,
  Menu,
  MenuItem,
  IconButton
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Button from '@mui/material/Button';
import { useLocation, useNavigate, a } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changePageflag } from 'feactures/date/dateSlice';
import banner_sidemenu from './images/banner_sidemenu.png';
import oxcim_new_logo from './images/oxcim_new_logo.png';
import bar_graph_icon from './icons/bar_graph.svg';
import circle_graph_icon from './icons/circle_graph.svg';
import circle_graph_icon_red from './icons/circle_graph_red.svg';
import banner_free from './images/banner_free.png';
import banner_year from './images/banner_year.png';
import banner_ope from './images/banner_ope.png';
import banner_trial from './images/banner_trial.png';
import banner_trial_rakuten from './images/banner_trial_rakuten.png';
import banner_for_new_account from './images/banner_for_new_account.png';
import line_graph_icon from './icons/line_graph.svg';
import human_icon from './icons/human.svg';
import freeBadge from './icons/freebadge.png';
import freeIcon from './icons/freeIcon.png';
import { userinfo, fetchLogout } from 'feactures/user/userSlice';
import { sideMenuProps } from 'feactures/section/pageSlice';
import { changePage } from 'feactures/date/dateSlice';
import SettingsIcon from '@mui/icons-material/Settings';

import moment from 'moment';

const SideMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const menuId = 'side-menu';
  const user = useSelector(userinfo);
  const sideMenuOpen = useSelector(sideMenuProps);
  let trialFlag = false; //MKV全ページ利用可
  let preTrialFlag = false; //施策分析のみ利用可
  let premiumFlag = false; //有料プラン利用

  if (
    user.trial.Amazon.trial ||
    (user.trial.楽天.trial && user.rakutenStatus >= 7)
  ) {
    trialFlag = true;
  }
  if (user.trial.楽天.trial) {
    preTrialFlag = true;
  }
  if (user.trial.Amazon.trial) {
    preTrialFlag = true;
  }

  //施策分析トライアルだったらtrialFlagはfalseにする
  if (user.trial.楽天.trial && user.plan.rakuten.plan_id == 5) {
    trialFlag = false;
    preTrialFlag = true;
  }
  if (user.trial.Amazon.trial && user.plan.amazon.plan_id == 5) {
    trialFlag = false;
    preTrialFlag = true;
  }

  //審査前のアカウントの場合どっちもfalse
  if (user.accountStatus == 0) {
    trialFlag = false;
    preTrialFlag = false;
  }

  //有料プランフラグ
  if (
    user.plan.amazon.plan != 'トライアルプラン' &&
    user.plan.amazon.status == 1
  ) {
    premiumFlag = true;
  } else if (
    user.plan.rakuten.plan != 'トライアルプラン' &&
    user.plan.rakuten.status == 1
  ) {
    premiumFlag = true;
  } else if (user.masterUser) {
    premiumFlag = true;
  }

  const handleLogout = async () => {
    await dispatch(fetchLogout())
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        setTimeout(function () {
          navigate('/login');
        }, 2000);
      });
    navigate('/login', { replace: false });
  };

  const CustomButton = styled(Button)({
    textDecoration: 'none',
    color: '#fff',
    borderRadius: 0,
    height: '30px',
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '5px 25px 5px 50px',
    // '@media screen and (max-height:800px)': {
    //   height: '35px',
    //   padding: '5px 20px 5px 25px'
    // },
    // '@media screen and (max-height:700px)': {
    //   height: '28px',
    //   padding: '0px 20px 0px 25px',
    //   fontSize: '14px'
    // },
    borderLeft: '4px solid transparent',
    transition: 'all 0.3s ease',
    // textDecoration: 'underline',
    fontSize: '14px',
    '&:hover': {
      color: '#007bff',
      backgroundColor: '#f2f2f2'
      // textDecoration: 'underline',
      // borderLeft: '4px solid #007bff'
    }
  });

  const SelectedButton = styled(Button)({
    textDecoration: 'none',
    color: '#718CC6',
    backgroundColor: '#FFF8E5',
    borderRadius: 0,
    height: '30px',
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '5px 25px 5px 50px',
    // '@media screen and (max-height:800px)': {
    //   height: '35px',
    //   padding: '5px 20px 5px 25px'
    // },
    // '@media screen and (max-height:700px)': {
    //   height: '28px',
    //   padding: '0px 20px 0px 25px',
    //   fontSize: '14px'
    // },
    borderLeft: '4px solid transparent',
    transition: 'all 0.3s ease',
    // textDecoration: 'underline',
    fontSize: '14px',
    '&:hover': {
      color: '#007bff',
      backgroundColor: '#f2f2f2'
      // textDecoration: 'underline',
      // borderLeft: '4px solid #007bff'
    }
  });

  const MarketCustomButton = styled(Button)({
    textDecoration: 'none',
    color: '#fff',
    borderRadius: 0,
    minHeight: '30px',
    height: 'auto',
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'start',
    padding: trialFlag ? '5px 25px 5px 19px' : '5px 25px 5px 50px',
    // '@media screen and (max-height:800px)': {
    //   height: '35px',
    //   padding: '5px 20px 5px 25px'
    // },
    // '@media screen and (max-height:700px)': {
    //   height: '28px',
    //   padding: '0px 20px 0px 25px',
    //   fontSize: '14px'
    // },
    borderLeft: '4px solid transparent',
    transition: 'all 0.3s ease',
    // textDecoration: 'underline',
    fontSize: '14px',
    '&:hover': {
      color: '#007bff',
      backgroundColor: '#f2f2f2'
      // textDecoration: 'underline',
      // borderLeft: '4px solid #007bff'
    }
  });

  const MarketSelectedButton = styled(Button)({
    textDecoration: 'none',
    color: '#718CC6',
    backgroundColor: '#FFF8E5',
    borderRadius: 0,
    minHeight: '30px',
    height: 'auto',
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'start',
    padding: trialFlag ? '5px 25px 5px 19px' : '5px 25px 5px 50px',
    // '@media screen and (max-height:800px)': {
    //   height: '35px',
    //   padding: '5px 20px 5px 25px'
    // },
    // '@media screen and (max-height:700px)': {
    //   height: '28px',
    //   padding: '0px 20px 0px 25px',
    //   fontSize: '14px'
    // },
    borderLeft: '4px solid transparent',
    transition: 'all 0.3s ease',
    // textDecoration: 'underline',
    fontSize: '14px',
    '&:hover': {
      color: '#007bff',
      backgroundColor: '#f2f2f2'
      // textDecoration: 'underline',
      // borderLeft: '4px solid #007bff'
    }
  });

  const MarketCustomButtonMeasuresOnly = styled(Button)({
    textDecoration: 'none',
    color: '#fff',
    borderRadius: 0,
    minHeight: '30px',
    height: 'auto',
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'start',
    padding: preTrialFlag ? '5px 25px 5px 19px' : '5px 25px 5px 50px',
    // '@media screen and (max-height:800px)': {
    //   height: '35px',
    //   padding: '5px 20px 5px 25px'
    // },
    // '@media screen and (max-height:700px)': {
    //   height: '28px',
    //   padding: '0px 20px 0px 25px',
    //   fontSize: '14px'
    // },
    borderLeft: '4px solid transparent',
    transition: 'all 0.3s ease',
    // textDecoration: 'underline',
    fontSize: '14px',
    '&:hover': {
      color: '#007bff',
      backgroundColor: '#f2f2f2'
      // textDecoration: 'underline',
      // borderLeft: '4px solid #007bff'
    }
  });

  const MarketSelectedButtonMeasuresOnly = styled(Button)({
    textDecoration: 'none',
    color: '#718CC6',
    backgroundColor: '#FFF8E5',
    borderRadius: 0,
    minHeight: '30px',
    height: 'auto',
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'start',
    padding: preTrialFlag ? '5px 25px 5px 19px' : '5px 25px 5px 50px',
    // '@media screen and (max-height:800px)': {
    //   height: '35px',
    //   padding: '5px 20px 5px 25px'
    // },
    // '@media screen and (max-height:700px)': {
    //   height: '28px',
    //   padding: '0px 20px 0px 25px',
    //   fontSize: '14px'
    // },
    borderLeft: '4px solid transparent',
    transition: 'all 0.3s ease',
    // textDecoration: 'underline',
    fontSize: '14px',
    '&:hover': {
      color: '#007bff',
      backgroundColor: '#f2f2f2'
      // textDecoration: 'underline',
      // borderLeft: '4px solid #007bff'
    }
  });

  const handleClick = (e, path, page) => {
    e.preventDefault();
    navigate(`/app${path}`);
    // dispatchでpathの中身を渡す→DashboardNavbarで受け取って、マーケットのpathだったら更新ボタンを非表示にする
    dispatch(changePageflag(path));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const page = location.pathname;
  const isMenuOpen = Boolean(anchorEl);
  const amazonStartDate = user.plan.amazon.start_date;
  const amazonEndDate = user.plan.amazon.end_date;
  const rakutenStartDate = user.plan.rakuten.start_date;
  const rakutenEndDate = user.plan.rakuten.end_date;
  const yahooStartDate = user.plan.yahoo.start_date;
  const yahooEndDate = user.plan.yahoo.end_date;

  let yearContract = false;
  if (amazonStartDate !== '' && amazonEndDate !== '') {
    const start = moment(amazonStartDate);
    const end = moment(amazonEndDate).add(1, 'days');
    const diff = end.diff(start, 'month');
    if (diff === 12) {
      yearContract = true;
    }
  }
  if (rakutenStartDate !== '' && rakutenEndDate !== '') {
    const start = moment(rakutenStartDate);
    const end = moment(rakutenEndDate).add(1, 'days');
    const diff = end.diff(start, 'month');
    if (diff === 12) {
      yearContract = true;
    }
  }
  if (yahooStartDate !== '' && yahooEndDate !== '') {
    const start = moment(yahooStartDate);
    const end = moment(yahooEndDate).add(1, 'days');
    const diff = end.diff(start, 'month');
    if (diff === 12) {
      yearContract = true;
    }
  }

  let banner_url;
  let banner_path;
  const createdAt = new Date(user.createdAt);
  const jstCreatedAt = new Date(createdAt.getTime() - 9 * 60 * 60 * 1000);
  const targetDate = new Date('2024-07-31T16:00:00+09:00');
  // バナーの出し分け
  if (
    (jstCreatedAt >= targetDate && // アカウント開設が2024年8月1日以降かどうか
      user.plan.amazon.plan === 'ご利用なし' &&
      user.trial.Amazon.end_date === '' &&
      user.plan.rakuten.plan === 'ご利用なし' &&
      user.trial.楽天.end_date === '') || //　いずれのモールでもトライルを使ったとが無い
    (jstCreatedAt >= targetDate && user.trial.楽天.trial) ||
    (jstCreatedAt >= targetDate && user.trial.Amazon.trial) // どちらかのモールでトライアルを利用中
  ) {
    banner_url = banner_for_new_account;
    banner_path = 'new_trial_premium_register';
  } else if (
    user.plan.amazon.plan === 'ご利用なし' &&
    user.plan.rakuten.plan === 'ご利用なし' &&
    user.plan.yahoo.plan === 'ご利用なし'
  ) {
    banner_url = banner_free;
    banner_path = 'https://www.oxcim.net/plan-change';
  } else if (user.trial.Amazon.trial) {
    banner_url = banner_for_new_account;
    banner_path = 'trial_premium_register/prev?trial=amazon';
  } else if (user.trial.楽天.trial) {
    banner_url = banner_trial;
    banner_path = 'https://www.oxcim.net/plan-change';
  } else if (yearContract) {
    banner_url = banner_ope;
    banner_path = 'https://www.oxcim.net/plan-change';
  } else {
    banner_url = banner_year;
    banner_path = 'https://www.oxcim.net/plan-change';
  }

  const bannerClick = (e) => {
    if (banner_path == 'https://www.oxcim.net/plan-change') {
      // 新しいタブで開く場合
      window.open(banner_path, '_blank');
    } else {
      // 同じタブ内で遷移する場合
      e.preventDefault();
      navigate(`/${banner_path}`);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePlan = () => {
    handleMenuClose();
    navigate('/app/sys/plan');
  };
  const handleBilling = () => {
    handleMenuClose();
    navigate('/app/sys/billing');
  };

  const handleConnectionStatus = () => {
    handleMenuClose();
    navigate('/app/sys/connection_status');
  };

  const handleRms = () => {
    handleMenuClose();
    navigate('/app/sys/rms');
  };

  const handleChangePass = () => {
    handleMenuClose();
    navigate('/app/sys/p/edit');
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user.accountStatus != 0 && (
        <MenuItem onClick={handlePlan}>プラン管理</MenuItem>
      )}
      {user.accountStatus != 0 && (
        <MenuItem onClick={handleBilling}>ご請求情報</MenuItem>
      )}
      <MenuItem onClick={handleConnectionStatus}>データ接続状況</MenuItem>
      <MenuItem onClick={handleRms}>楽天RMS連携設定</MenuItem>
      <MenuItem disabled>Yahoo!API連携設定</MenuItem>
      <MenuItem onClick={handleChangePass}>パスワード変更</MenuItem>
      <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
    </Menu>
  );

  const TrialIcon = () => (
    <img
      src={freeIcon}
      alt="トライアルアイコン"
      style={{ width: '27px', marginRight: '3px' }}
    />
  );

  return (
    <>
      <Box
        position="fixed"
        zIndex={20}
        height="100vh"
        width="240px"
        bgcolor="#718CC6"
        sx={{
          transition: 'left 0.3s',
          left: sideMenuOpen ? 0 : '-100%'
        }}
      >
        <Box
          overflow="auto"
          position="fixed"
          height="100%"
          width="240px"
          color="#fff"
          sx={{
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
            transition: 'left 0.3s',
            left: sideMenuOpen ? 0 : '-100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <img src={oxcim_new_logo} alt="logo" style={{ width: '60%' }} />
            <Box
              sx={{
                border: '1px solid #fff',
                borderRadius: '50px',
                width: '55px',
                height: '55px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                src={human_icon}
                alt="human-icon"
                style={{ width: '40px', height: '40px' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%'
              }}
            >
              <Box sx={{ width: '80%' }}>
                <Typography
                  fontSize={'16px'}
                  color="#fff"
                  component={'p'}
                  align="center"
                >
                  {user.accountName}&nbsp;様
                </Typography>
              </Box>
              <IconButton
                onClick={handleProfileMenuOpen}
                sx={{ color: '#fff' }}
              >
                <SettingsIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider
            sx={{ my: 2, border: '1px solid #fff', width: '80%', mx: 'auto' }}
          />
          {/* <Box sx={{ mb: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: '20px' }}>
              <img
                src={graph_icon}
                alt="グラフアイコン"
                style={{ width: '20px' }}
              />
              <Typography
                fontSize="17px"
                fontWeight="600"
                color="#fff"
                pl="10px"
                letterSpacing="1px"
              >
                オーバービュー
              </Typography>
            </Box>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                {page === '/app/overview' ? (
                  <SelectedButton
                    value="/overview"
                    onClick={(e) => handleClick(e, '/overview', 'overview')}
                    disabled={user.accountStatus == 0}
                  >
                    -&nbsp;全体実績
                  </SelectedButton>
                ) : (
                  <CustomButton
                    value="/overview"
                    onClick={(e) => handleClick(e, '/overview', 'overview')}
                    disabled={user.accountStatus == 0}
                  >
                    -&nbsp;全体実績
                  </CustomButton>
                )}
              </li>
            </ul>
          </Box> */}
          <Box sx={{ mb: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: '20px' }}>
              <img
                src={bar_graph_icon}
                alt="棒グラフアイコン"
                style={{ width: '20px' }}
              />
              <Typography
                fontSize="17px"
                fontWeight="600"
                pl="10px"
                letterSpacing="1px"
                color="#fff"
              >
                KPIビュー
              </Typography>
            </Box>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                {page === '/app/overview' ? (
                  <SelectedButton
                    value="/overview"
                    onClick={(e) => handleClick(e, '/overview', 'overview')}
                    disabled={user.accountStatus == 0}
                  >
                    -&nbsp;1.全体実績
                  </SelectedButton>
                ) : (
                  <CustomButton
                    value="/overview"
                    onClick={(e) => handleClick(e, '/overview', 'overview')}
                    disabled={user.accountStatus == 0}
                  >
                    -&nbsp;1.全体実績
                  </CustomButton>
                )}
              </li>
            </ul>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                {page === '/app/monitoring' ? (
                  <SelectedButton
                    value="/monitoring"
                    onClick={(e) => handleClick(e, '/monitoring', 'monitoring')}
                    disabled={user.accountStatus == 0}
                  >
                    -&nbsp;2.KPIサマリ
                  </SelectedButton>
                ) : (
                  <CustomButton
                    value="/monitoring"
                    onClick={(e) => handleClick(e, '/monitoring', 'monitoring')}
                    disabled={user.accountStatus == 0}
                  >
                    -&nbsp;2.KPIサマリ
                  </CustomButton>
                )}
              </li>
              {/* <li
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start'
            }}
          >
            <i style={{ marginRight: '10px' }}></i>
            {page === '/app/sales' ? (
              <SelectedButton
                value="/sales"
                onClick={(e) => handleClick(e, '/sales')}
              >
                商品別売上
              </SelectedButton>
            ) : (
              <CustomButton
                value="/sales"
                onClick={(e) => handleClick(e, '/sales')}
              >
                商品別売上
              </CustomButton>
            )}
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start'
            }}
          >
            <i style={{ marginRight: '10px' }}></i>
            <CustomButton
              value="/numberviews"
              onClick={(e) => handleClick(e, '/numberviews')}
            >
              閲覧数
            </CustomButton>
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start'
            }}
          >
            <i style={{ marginRight: '10px' }}></i>
            <CustomButton value="/cvr" onClick={(e) => handleClick(e, '/cvr')}>
              CVR
            </CustomButton>
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start'
            }}
          >
            <i style={{ marginRight: '10px' }}></i>
            {page === '/app/unitprice' ? (
              <SelectedButton
                value="/unitprice"
                onClick={(e) => handleClick(e, '/unitprice')}
              >
                販売価格
              </SelectedButton>
            ) : (
              <CustomButton
                value="/unitprice"
                onClick={(e) => handleClick(e, '/unitprice')}
              >
                販売価格
              </CustomButton>
            )}
          </li> */}
            </ul>
          </Box>
          <Box sx={{ mb: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: '20px' }}>
              <img
                src={circle_graph_icon}
                alt="円グラフアイコン"
                style={{ width: '20px', stroke: '#FFE4DC' }}
              />
              <Typography
                fontSize="17px"
                fontWeight="600"
                pl="10px"
                letterSpacing="1px"
              >
                マーケットビュー
              </Typography>
            </Box>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                {page === '/app/newcategoryboard' ? (
                  <MarketSelectedButton
                    value="/newcategoryboard"
                    onClick={(e) =>
                      handleClick(e, '/newcategoryboard', 'newCategoryBoard')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {trialFlag && <TrialIcon />}
                    -&nbsp;1.カテゴリ分析
                  </MarketSelectedButton>
                ) : (
                  <MarketCustomButton
                    value="/newcategoryboard"
                    onClick={(e) =>
                      handleClick(e, '/newcategoryboard', 'newCategoryBoard')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {trialFlag && <TrialIcon />}
                    -&nbsp;1.カテゴリ分析
                  </MarketCustomButton>
                )}
              </li>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                {page === '/app/brandanalysis' ? (
                  <MarketSelectedButton
                    value="/brandanalysis"
                    onClick={(e) =>
                      handleClick(e, '/brandanalysis', 'brandAnalysis')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {trialFlag && <TrialIcon />}
                    -&nbsp;2.店舗/ブランド分析
                  </MarketSelectedButton>
                ) : (
                  <MarketCustomButton
                    value="/brandanalysis"
                    onClick={(e) =>
                      handleClick(e, '/brandanalysis', 'brandAnalysis')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {trialFlag && <TrialIcon />}
                    -&nbsp;2.店舗/ブランド分析
                  </MarketCustomButton>
                )}
              </li>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                {page === '/app/otherproductboard' ? (
                  <MarketSelectedButton
                    value="/otherproductboard"
                    onClick={(e) =>
                      handleClick(e, '/otherproductboard', 'otherProductBoard')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {trialFlag && <TrialIcon />}
                    -&nbsp;3.商品分析
                  </MarketSelectedButton>
                ) : (
                  <MarketCustomButton
                    value="/otherproductboard"
                    onClick={(e) =>
                      handleClick(e, '/otherproductboard', 'otherProductBoard')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {trialFlag && <TrialIcon />}
                    -&nbsp;3.商品分析
                  </MarketCustomButton>
                )}
              </li>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                {page === '/app/brandcomparison' ? (
                  <MarketSelectedButton
                    value="/brandcomparison"
                    onClick={(e) =>
                      handleClick(e, '/brandcomparison', 'brandComparison')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {trialFlag && <TrialIcon />}
                    -&nbsp;4.自社比較分析
                  </MarketSelectedButton>
                ) : (
                  <MarketCustomButton
                    value="/brandcomparison"
                    onClick={(e) =>
                      handleClick(e, '/brandcomparison', 'brandComparison')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {trialFlag && <TrialIcon />}
                    -&nbsp;4.自社比較分析
                  </MarketCustomButton>
                )}
              </li>
            </ul>
          </Box>
          <Box sx={{ mb: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: '20px' }}>
              <img
                src={line_graph_icon}
                alt="折れ線グラフアイコン"
                style={{ width: '20px', stroke: '#FFE4DC' }}
              />
              <Typography
                fontSize="17px"
                fontWeight="600"
                pl="10px"
                letterSpacing="1px"
              >
                競合ビュー
              </Typography>
            </Box>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                {/* <i style={{ marginRight: '10px' }}></i> */}
                {page === '/app/measureanalysis' ? (
                  <MarketSelectedButtonMeasuresOnly
                    value="/measureanalysis"
                    onClick={(e) =>
                      handleClick(e, '/measureanalysis', 'measureAnalysis')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {preTrialFlag && <TrialIcon />}
                    -&nbsp;1.競合施策分析
                  </MarketSelectedButtonMeasuresOnly>
                ) : (
                  <MarketCustomButtonMeasuresOnly
                    value="/measureanalysis"
                    onClick={(e) =>
                      handleClick(e, '/measureanalysis', 'measureAnalysis')
                    }
                    disabled={user.accountStatus == 0}
                  >
                    {preTrialFlag && <TrialIcon />}
                    -&nbsp;1.競合施策分析
                  </MarketCustomButtonMeasuresOnly>
                )}
              </li>
              {premiumFlag && (
                <li
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start'
                  }}
                >
                  {/* <i style={{ marginRight: '10px' }}></i> */}
                  {page === '/app/performancereview' ? (
                    <MarketSelectedButtonMeasuresOnly
                      value="/performancereview"
                      onClick={(e) =>
                        handleClick(
                          e,
                          '/performancereview',
                          'performanceReview'
                        )
                      }
                      disabled={user.accountStatus == 0}
                    >
                      {preTrialFlag && <Box width="30px"></Box>}
                      -&nbsp;2.商品販売力診断β版
                    </MarketSelectedButtonMeasuresOnly>
                  ) : (
                    <MarketCustomButtonMeasuresOnly
                      value="/performancereview"
                      onClick={(e) =>
                        handleClick(
                          e,
                          '/performancereview',
                          'performanceReview'
                        )
                      }
                      disabled={user.accountStatus == 0}
                    >
                      {preTrialFlag && <Box width="30px"></Box>}
                      -&nbsp;2.商品販売力診断β版
                    </MarketCustomButtonMeasuresOnly>
                  )}
                </li>
              )}
            </ul>
          </Box>
          {user.accountStatus != 0 &&
            user.accountId != 11 &&
            user.accountId != 84 && (
              <Box sx={{ my: '20px' }}>
                <a
                  onClick={bannerClick}
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <img
                    src={banner_url}
                    alt="サイドメニューのバナー"
                    style={{ width: '90%' }}
                  />
                </a>
              </Box>
            )}
        </Box>
      </Box>
      {renderMenu}
    </>
  );
};

export default SideMenu;
