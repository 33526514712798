/* eslint-disable*/
import { Box, Rating, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import PerformanceRadar from './PerformanceRadar';
import PerformanceScoreTracker from './PerformanceScoreTracker';
import PerformanceReviewRegisterDialog from 'components/atoms/modal/PerformanceReviewRegisterDialog';
import { useSelector } from 'react-redux';
import {
  performancereviewAmazonOtherItemData,
  performancereviewAmazonOwnItemData,
  performancereviewRakutenOtherItemData,
  performancereviewRakutenOwnItemData
} from 'feactures/performancereview/performancereviewSlice';
import { currentchannel } from 'feactures/channel/channelSlice';

const ItemBox = (props) => {
  const { section } = props;
  const amazonOwnPerformanceData = useSelector(
    performancereviewAmazonOwnItemData
  );
  const rakutenOwnPerformanceData = useSelector(
    performancereviewRakutenOwnItemData
  );
  const amazonOtherPerformanceData = useSelector(
    performancereviewAmazonOtherItemData
  );
  const rakutenOtherPerformanceData = useSelector(
    performancereviewRakutenOtherItemData
  );
  const channel = useSelector(currentchannel);
  let color;
  let buttonColor;
  let sectionName;
  let data;
  if ((section == 'own') & (channel == 1)) {
    color = 'rgba(113,140,198,1)';
    buttonColor = 'groupSelected';
    sectionName = '自社';
    data = amazonOwnPerformanceData;
  } else if ((section == 'other') & (channel == 1)) {
    color = '#ffa500';
    buttonColor = 'otherButton';
    sectionName = '競合';
    data = amazonOtherPerformanceData;
  } else if ((section == 'own') & (channel == 2)) {
    color = 'rgba(113,140,198,1)';
    buttonColor = 'groupSelected';
    sectionName = '自社';
    data = rakutenOwnPerformanceData;
  } else if ((section == 'other') & (channel == 2)) {
    color = '#ffa500';
    buttonColor = 'otherButton';
    sectionName = '競合';
    data = rakutenOtherPerformanceData;
  }

  const url = 'https://m.media-amazon.com/images/I/';

  return (
    <Box
      width="50%"
      p={1}
      borderRight={section == 'own' ? 1 : 0}
      borderColor="#BDBDBD"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography fontWeight="bold" color={color} fontSize="15px">
          {sectionName}商品
        </Typography>
        <PerformanceReviewRegisterDialog
          section={section}
          itemId={data.itemId}
        />
      </Box>
      {!data.itemName ? (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>商品を選択してください</Typography>
        </Box>
      ) : (
        <>
          {/* グレーのボックス */}
          <Box
            p={1}
            borderRadius={3}
            bgcolor="#F3F4F6"
            width="100%"
            display="flex"
          >
            <Box
              bgcolor="#E5E7EB"
              width="80px"
              height="80px"
              mr={1}
              borderRadius={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                alt="item_img"
                src={channel == 1 ? url + data.imgUrl : data.imgUrl}
                style={{
                  display: 'inline-block',
                  width: '80px',
                  height: '80px'
                }}
              />
            </Box>
            <Box width="calc(100% - 78px)">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                pb={1}
              >
                <Tooltip title={data.itemName} placement="top" arrow>
                  <Typography
                    fontWeight="bold"
                    fontSize="13px"
                    color="black"
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {data.itemName}
                  </Typography>
                </Tooltip>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  minWidth="80px"
                >
                  <Typography fontWeight="bold">現在のスコア</Typography>
                  {data.score == 'nodata' ? (
                    <Typography
                      width="40px"
                      height="40px"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      -
                    </Typography>
                  ) : (
                    <img
                      alt="score"
                      src={`/static/images/performanceReview/rank_${data.score}.png`}
                      style={{
                        display: 'inline-block',
                        width: '40px'
                      }}
                    />
                  )}

                  {/* <ScoreCircle dataGrade={data.score} /> */}
                </Box>
              </Box>
              <Box width="100%">
                <Box>
                  <Typography>
                    カテゴリ：{data.categoryName == 0 ? '-' : data.categoryName}
                    <br />
                    価格：
                    {data.price == 0 ? '-' : `¥${data.price.toLocaleString()}`}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography>レビュー：</Typography>
                    <Rating
                      value={data.reviewScore}
                      precision={0.5}
                      readOnly
                      size="small"
                    />
                    <Typography fontWeight="bold">
                      {data.reviewScore}({data.reviewNum.toLocaleString()})
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <Typography fontWeight="bold">平均ランキング：</Typography>
                    <Typography color={color}>
                      {data.aveRank == -99
                        ? '圏外'
                        : data.aveRank == 0
                        ? '圏外'
                        : `${data.aveRank}位`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Box
          display="flex"
          justifyContent="space-between"
          width="calc(100% - 78px)"
        >
          <Box>
            <Typography fontWeight="bold" fontSize="13px" color="black">
              自社商品A
            </Typography>
            <Typography>
              カテゴリ：エレクトロニクス
              <br />
              価格：¥15,000
            </Typography>
            <Typography fontWeight="bold" pt={1}>
              平均ランキング：
            </Typography>
            <Typography color={color}>5位</Typography>
          </Box>
          <Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Typography mr={1} fontWeight="bold">
                現在のスコア：
              </Typography>
              <ScoreCircle dataGrade="S" />
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Typography>レビュー：</Typography>
              <Rating value={4.5} precision={0.5} readOnly size="small" />
              <Typography>4.5(120)</Typography>
            </Box>
          </Box>
        </Box> */}
          </Box>
          {/* レーダーグラフ */}
          <PerformanceRadar section={section} />
          {/* スコア推移 */}
          <PerformanceScoreTracker section={section} />
        </>
      )}
    </Box>
  );
};

export default ItemBox;
